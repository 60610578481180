/* Social
================================================== */
.social {
  ul {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    width: auto;
    margin: 0.5rem;
    padding: 0;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;

    * {
      fill: $black;
      @include transition($global-transition);
    }
  }

  p {
    display: flex;
    margin: 0;
  }

  a {
    display: block;
    padding: 0.5rem;
    &:hover {
      * {
        fill: $secondary-colour;
      }
    }
  }

  .social-fixed & {
    ul {
      position: fixed;
      top: 25%;
      right: 0;
      z-index: 100;
      display: none;
      margin: 0;
      @include bp(xlarge) {
        display: block;
      }
    }
    h3 {
      display: none;
    }
    li,
    a {
      width: 48px;
      height: 48px;
    }
    li {
      margin: 1rem;
      padding: 0;
      text-align: center;
    }
    span {
      display: none;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 14px;
      line-height: 100%;
      border-radius: 50%;
      background-color: $off-white;
      @include transition($global-transition);
      &:hover {
        background-color: $secondary-colour;
        * {
          fill: $black;
        }
      }
    }
  }
}

/* Countdown
================================================== */
.event-countdown {
  ul {
    display: flex;
    flex-flow: column;
    align-items: normal;
    justify-content: normal;
    position: relative;
    z-index: 1;
    margin: 1rem auto;
    padding: 0;
    text-align: center;
    font-size: 1.2rem;
    @include bp(smedium) {
      flex-flow: row wrap;
    }
  }
  p {
    display: none;
  }
  li {
    display: inline-flex;
    justify-content: center;
    flex: 1;
    @extend .columns;
    @extend .small-12;
    margin: 0;
    padding: 1rem;
    list-style: none;
    text-align: center;
    color: $primary-colour;
    @include bp(smedium) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include bp(medium) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    div {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
    }
    span {
      line-height: 100%;
    }
  }
  &__number {
    font-size: 2.6rem;
    font-weight: 600;
    @include bp(xlarge) {
      font-size: 3rem;
    }
  }
  &__text {
    margin-top: 1rem;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 600;
    @include bp(xlarge) {
      font-size: 1.2rem;
    }
  }
}

/* Stats
================================================== */
.stat {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  &__number {
    display: flex;
    font-size: 2.6rem;
    font-weight: 600;
    @include bp(xlarge) {
      font-size: 3rem;
    }
  }
  &__text {
    margin-top: 0;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
    @include bp(xlarge) {
      font-size: 1.2rem;
    }
  }
}

/* Map
================================================== */
.map-card {
  max-width: 600px;
  margin: auto;
  @extend .box-shadow-style-1;
  * {
    color: $base-font-color !important;
  }
  h6 {
    display: none;
  }
  p {
    margin: 0;
  }
  .event-location {
    padding: 1.5rem;
    background-color: $white;
    ul {
      padding: 0;
    }
    li {
      display: inline-flex;
      margin: 0;
      padding: 0 0.5rem 0 0;
    }
    &__name {
      width: 100%;
      margin-bottom: 0.5rem !important;
      font-size: 1.2rem;
      strong {
        font-weight: 600;
      }
    }
  }
}

/* Sliders
================================================== */
.n2-section-smartslider {
  .n2-bullet {
    &.n2-active {
      border-color: $secondary-colour !important;
    }
  }
}

/* Testimonials
================================================== */
.testimonials-slider {
  &__image {
    padding: 1.5rem;
    background-color: $white;
    border-radius: 50%;
    border: 4px solid $secondary-colour;
  }
  &__quote {
    .n2-ss-item-image-content {
      display: flex;
      position: relative;
      top: -1rem;
      @include bp(large) {
        top: 0;
        left: -4rem;
      }
    }
  }
}

/* Gallery Slider
================================================== */
.showcase-slider {
  .n2-ss-slider-1 {
    @include bp_max(mlarge) {
      min-height: 400px !important;
    }
    @include bp_max(medium) {
      min-height: 360px !important;
    }
  }
  .n2-ss-slide-active {
    @extend .box-shadow-style-2;
  }
  .n2-ss-static-slide {
    z-index: -1 !important;
  }
}

/* Speaker Slider
================================================== */
.speaker-slider {
  &__image {
    overflow: hidden;
    background-color: $white;
    border-radius: 50%;
    border: 4px solid $tertiary-colour;
  }
  &__title {
    margin-bottom: 0.75rem;
    font-size: 1rem;
    font-weight: 500;
  }
  &__details {
    font-size: 0.8rem;
    p {
      margin: 0 0 0.25rem 0;
    }
  }
}

/* Newsletter Form
================================================== */
.newsletter-form {
  .ba-form-page {
    .ba-form-field-item,
    .ba-form-column,
    .ba-form-submit-btn {
      margin: 0 !important;
    }
    .ba-form-column-wrapper .ba-form-column {
      align-self: center;
    }
    [class*='span'] {
      width: auto !important;
    }
    input[type='email'],
    .ba-form-submit-btn {
      min-height: 60px;
      border-radius: 0 !important;
    }
  }
}

/* Cards
================================================== */
.cards {
  gap: 3rem;
  &--narrow {
    max-width: 1024px;
    margin: auto;
  }
  .wf-columns {
    height: 100%;
  }
  .wf-column,
  .card {
    position: relative;
    margin-bottom: 2rem;
    padding: 2rem;
    background-color: $off-white;
    * {
      color: $black;
    }
    a {
      color: $tertiary-colour;
      &:hover {
        color: $black;
      }
      &.button {
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }
  }
  figure {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 320px !important;
    margin-left: -2rem !important;
    margin-top: -2rem !important;
    overflow: hidden;
    padding-bottom: 1rem;
    width: calc(100% + 4rem);
    img {
      width: 100% !important;
      height: 100% !important;
      -o-object-fit: cover !important;
      object-fit: cover !important;
      -o-object-position: center !important;
      object-position: center !important;
    }
  }
}

/* Accordion
================================================== */
.accordion {
  padding: 0;
  background: transparent !important;
  * {
    color: $black !important;
  }
  &-item {
    margin-bottom: 0;
    &.is-active {
      .accordion-title {
        background-color: darken($off-white, 8%);
        &:before {
          color: $secondary-colour;
        }
      }
    }
  }
  &-title,
  &-content {
    overflow: hidden;
    margin-bottom: 0.25rem;
    border-radius: 0px !important;
    border: 0 !important;
  }
  &-title {
    margin: 0;
    padding: 1.25rem 2.5rem 1.25rem 1rem;
    background-color: $off-white;
    border-bottom: 2px solid $tertiary-colour !important;
    &:hover {
      background-color: darken($off-white, 8%);
      &:before {
        color: $secondary-colour;
      }
    }
    &:before {
      margin-top: -0.7rem;
      font-size: 1.4rem;
    }
    * {
      margin: 0;
      font-size: 1.2rem;
      font-family: $primary-font;
      font-weight: bold;
    }
  }
  &-content {
    padding-top: 2rem;
    a {
      color: $tertiary-colour !important;
      &:hover {
        color: $black !important;
      }
    }
  }
}

/* Box Styling
================================================== */
.box-style {
  &-colour-1,
  &-colour-3,
  &-colour-4 {
    * {
      color: $white;
    }
  }
  &-colour-1,
  &-colour-2,
  &-colour-3,
  &-color-4 {
    padding: 2rem;
    .button {
      &--secondary,
      &--2 {
        color: $base-font-color;
      }
    }
  }
  &-colour-2 {
    ul li::marker {
      color: $quaternary-colour;
    }
  }
  &-colour-1 {
    background-color: $primary-colour;
  }
  &-colour-2 {
    background-color: $secondary-colour;
  }
  &-colour-3 {
    background-color: $tertiary-colour;
  }
  &-colour-4 {
    background-color: $quaternary-colour;
  }
}
